import React from 'react';

import FacebookIcon from '../../images/facebook.svg';
import TwitterIcon from '../../images/twitter.svg';
import LinkedInIcon from '../../images/linkedin.svg';
import PinterestIcon from '../../images/pinterest.svg';
import EmailIcon from '../../images/email.svg';

import './style.css';

export default function Share(props) {
  const { style, title } = props;
  const currentPage = typeof window !== 'undefined' && window.location.href;
  return (
    <ul className="shareList" style={style}>
      <li className="noBullet label">Share: </li>
      <li className="noBullet">
        <a
          title="Share on Facebook"
          href={`https://facebook.com/sharer/sharer.php?u=${currentPage}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ backgroundImage: `url(${FacebookIcon})` }}>
            Share on Facebook
          </span>
        </a>
      </li>
      <li className="noBullet">
        <a
          title="Share on Twitter"
          href={`https://twitter.com/intent/tweet/?text=${title}&url=${currentPage}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ backgroundImage: `url(${TwitterIcon})` }}>
            Share on Twitter
          </span>
        </a>
      </li>
      <li className="noBullet">
        <a
          title="Share on LinkedIn"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${currentPage}&title=${title}&summary=${title}&source=${currentPage}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ backgroundImage: `url(${LinkedInIcon})` }}>
            Share on LinkedIn
          </span>
        </a>
      </li>
      <li className="noBullet">
        <a
          title="Share on Pinterest"
          href={`https://pinterest.com/pin/create/button/?url=${currentPage}&media=${currentPage}&description=${title}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ backgroundImage: `url(${PinterestIcon})` }}>
            Share on Pinterest
          </span>
        </a>
      </li>
      <li className="noBullet">
        <a
          title="Share via Email"
          href={`mailto:?subject=${title}&body=${currentPage}`}
          target="_self"
        >
          <span style={{ backgroundImage: `url(${EmailIcon})` }}>
            Share via Email
          </span>
        </a>
      </li>
    </ul>
  );
}
